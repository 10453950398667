import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
  EmailField,
} from 'react-admin';

export default props => (
  <Show {...props} title="Mensagem">
    <SimpleShowLayout>
      <TextField source="subject" label="Assunto" />
      <RichTextField source="body" label="Texto" />
      <TextField source="name" label="Nome do Cliente" />
      <TextField source="phone" label="Celular" />
      <EmailField source="email" label="E-mail" />
      <TextField source="recipient" label="Destinatário" />
      <DateField source="date" label="Data" />
    </SimpleShowLayout>
  </Show>
);