import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  TextInput,
  DateField,
} from 'react-admin'

const MessageFilter = props => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
  </Filter>
)

export default props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    title="Mensagens"
    filters={<MessageFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label="De" />
      <TextField source="subject" label="Assunto" />
      <TextField source="recipient" label="Destinatário" />
      <DateField source="date" label="Data" />
    </Datagrid>
  </List>
)
