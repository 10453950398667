import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'

export default props => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title="Notificações"
    >
      <Datagrid>
        <TextField source="date" label="Data de Envio" />
        <TextField source="title" label="Titulo" />
        <TextField source="message" label="Mensagem" />
      </Datagrid>
    </List>
  )
}
