import NotificationsIcon from '@material-ui/icons/Notifications'
import { PERMISSION_TYPE } from '@/constants'
import list from './NotificationList'
import create from './NotificationCreate'

const { CHAIN_MANAGER } = PERMISSION_TYPE

export default {
  list,
  create,
  name: 'notifications',
  permission: [CHAIN_MANAGER],
  options: { label: 'Notificações' },
  icon: NotificationsIcon,
}
