import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  maxLength,
} from 'react-admin'

const validateTitle = [required(), minLength(3), maxLength(240)]
const validateMessage = [required(), minLength(3), maxLength(1500)]

export default props => (
  <Create {...props} title="Criar notificação">
    <SimpleForm>
      <TextInput source="title" label="Titulo" validate={validateTitle} />
      <TextInput
        source="message"
        label="Mensagem"
        multiline
        validate={validateMessage}
      />
    </SimpleForm>
  </Create>
)
