import { Resource } from '../index'
import Message from '@material-ui/icons/Message'
import { PERMISSION_TYPE } from '@/constants'
import list from './MessageList'
import show from './MessageShow'

const { CHAIN_MANAGER } = PERMISSION_TYPE

export default {
  list,
  show,
  name: 'messages',
  permission: [CHAIN_MANAGER],
  options: { label: 'Mensagens' },
  icon: Message,
} as Resource
